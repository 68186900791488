/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    percent: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M13.442 2.558a.625.625 0 010 .884l-10 10a.625.625 0 11-.884-.884l10-10a.625.625 0 01.884 0M4.5 6a1.5 1.5 0 110-3 1.5 1.5 0 010 3m0 1a2.5 2.5 0 100-5 2.5 2.5 0 000 5m7 6a1.5 1.5 0 110-3 1.5 1.5 0 010 3m0 1a2.5 2.5 0 100-5 2.5 2.5 0 000 5"/>',
    },
});
